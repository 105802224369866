import Mock from 'mockjs';
const qiYeList = [{
  id: 4,
  name: 'wx_bot',
  title: '机器人列表',
  icon: 'SmileOutlined',
  badge: '',
  target: '_self',
  path: '/gem/wx_bot',
  component: '@/pages/gem/wx_bot',
  renderMenu: true,
  parent: null,
  permission: null,
  cacheable: true,
}, ]
const presetList = [
  // {
  //   id: 1,
  //   name: 'workplace',
  //   title: '工作台',
  //   icon: 'DashboardOutlined',
  //   badge: 'new',
  //   target: '_self',
  //   path: '/workplace',
  //   component: '@/pages/workplace',
  //   renderMenu: true,
  //   parent: null,
  //   permission: null,
  //   cacheable: true,
  // },
  {
    id: 1,
    name: 'workplace',
    title: '企业信息',
    icon: 'AreaChartOutlined',
    badge: '',
    target: '_self',
    path: '/gem/workplace',
    component: '@/pages/gem/workplace',
    renderMenu: true,
    parent: null,
    permission: null,
    cacheable: true,
  },
  {
    id: 4,
    name: 'order_list',
    title: '订单管理',
    icon: 'BarcodeOutlined',
    badge: '',
    target: '_self',
    path: '/gem/order_list',
    component: '@/pages/gem/order_list',
    renderMenu: true,
    parent: null,
    permission: null,
    cacheable: true,
  },
  {
    id: 3,
    name: 'user_list',
    title: '回收人员列表',
    icon: 'UserOutlined',
    badge: '',
    target: '_self',
    path: '/gem/user_list',
    component: '@/pages/gem/user_list',
    renderMenu: true,
    parent: null,
    permission: null,
    cacheable: true,
  },
 
  {
    id: 2,
    name: 'store_list',
    title: '网点管理',
    icon: 'TableOutlined',
    badge: '',
    target: '_self',
    path: '/gem/store_list',
    component: '@/pages/gem/store_list',
    renderMenu: true,
    parent: null,
    permission: null,
    cacheable: true,
  },
  {
    id: 3,
    name: 'disassemble_list',
    title: '拆解',
    icon: 'BarcodeOutlined',
    badge: '',
    target: '_self',
    path: '/gem/disassemble_list',
    component: '@/pages/gem/disassemble_list',
    renderMenu: true,
    parent: null,
    permission: null,
    cacheable: true,
  },
 
  
  // {
  //   id: 2,
  //   name: 'wx_bot',
  //   title: '机器人列表',
  //   icon: 'SmileOutlined',
  //   badge: '',
  //   target: '_self',
  //   path: '/gem/wx_bot',
  //   component: '@/pages/gem/wx_bot',
  //   renderMenu: true,
  //   parent: null,
  //   permission: null,
  //   cacheable: true,
  // },
  // {
  //   id: 2,
  //   name: 'kb',
  //   title: '知识库列表',
  //   icon: 'SaveOutlined',
  //   badge: '',
  //   target: '_self',
  //   path: '/gem/kb',
  //   component: '@/pages/gem/kb_list',
  //   renderMenu: true,
  //   parent: null,
  //   permission: null,
  //   cacheable: true,
  // },
  // {
  //   id: 2,
  //   name: 'product',
  //   title: '套餐列表',
  //   icon: 'FireOutlined',
  //   badge: '',
  //   target: '_self',
  //   path: '/gem/product',
  //   component: '@/pages/gem/product',
  //   renderMenu: true,
  //   parent: null,
  //   permission: null,
  //   cacheable: true,
  // },
  // {
  //   id: 2,
  //   name: 'trade_list',
  //   title: '订单列表',
  //   icon: 'BarcodeOutlined',
  //   badge: '',
  //   target: '_self',
  //   path: '/gem/trade_list',
  //   component: '@/pages/gem/trade_list',
  //   renderMenu: true,
  //   parent: null,
  //   permission: null,
  //   cacheable: true,
  // },
  // {
  //   id: 2,
  //   name: 'biz',
  //   title: '行业管理',
  //   icon: 'TableOutlined',
  //   badge: '',
  //   target: '_self',
  //   path: '/gem/biz',
  //   component: '@/pages/gem/biz',
  //   renderMenu: true,
  //   parent: null,
  //   permission: null,
  //   cacheable: true,
  // },
  // {
  //   id: 6,
  //   name: 'setting',
  //   title: '系统配置',
  //   icon: 'SettingOutlined',
  //   badge: '',
  //   target: '_self',
  //   path: '/setting',
  //   component: '@/pages/gem/setting',
  //   renderMenu: true,
  //   parent: null,
  //   permission: null,
  //   cacheable: true,
  // },
  // {
  //   id: 10,
  //   name: 'dytong',
  //   title: '短译通',
  //   icon: 'GithubOutlined',

  //   target: '_blank',
  //   path: '/github',
  //   component: 'link',
  //   renderMenu: true,
  //   parent: null,
  //   cacheable: true,
  //   link: 'https://www.transdub.com/admin/index',
  // },
];

function getMenuList() {
  const menuStr = false; // localStorage.getItem('stepin-menu');
  let menuList = [];
  if (!menuStr) {
    menuList = presetList;
    localStorage.setItem('stepin-menu', JSON.stringify(menuList));
  } else {
    menuList = JSON.parse(menuStr);
  }
  return menuList;
}

function saveMenu(menu) {
  const menuList = getMenuList();
  if (!menu.id) {
    menu.id = menuList.map((item) => item.id).reduce((p, c) => Math.max(p, parseInt(c)), 0) + 1;
  }
  const index = menuList.findIndex((item) => item.id === menu.id);
  if (index === -1) {
    menuList.push(menu);
  } else {
    menuList.splice(index, 1, menu);
  }
  localStorage.setItem('stepin-menu', JSON.stringify(menuList));
}

export function getMenuListData() {
  let menuList = getMenuList();
  const menuMap = menuList.reduce((p, c) => {
    p[c.name] = c;
    return p;
  }, {});
  menuList.forEach((menu) => {
    menu.renderMenu = !!menu.renderMenu;
    if (menu.parent) {
      const parent = menuMap[menu.parent];
      parent.children = parent.children ?? [];
      parent.children.push(menu);
    }
  });
  const res = {
    msg: 'success',
    code: 200,
    data: menuList.filter((menu) => !menu.parent),
  };
  return res;
}

Mock.mock('api/menu', 'get', ({}) => {
  let menuList = getMenuList();
  const menuMap = menuList.reduce((p, c) => {
    p[c.name] = c;
    return p;
  }, {});
  menuList.forEach((menu) => {
    menu.renderMenu = !!menu.renderMenu;
    if (menu.parent) {
      const parent = menuMap[menu.parent];
      parent.children = parent.children ?? [];
      parent.children.push(menu);
    }
  });
  return {
    message: 'success',
    code: 0,
    data: menuList.filter((menu) => !menu.parent),
  };
});

Mock.mock('api/menu', 'put', ({
  body
}) => {
  const menu = JSON.parse(body);
  saveMenu(menu);
  return {
    code: 0,
    message: 'success',
  };
});

Mock.mock('api/menu', 'post', ({
  body
}) => {
  const menu = JSON.parse(body);
  saveMenu(menu);
  return {
    code: 0,
    message: 'success',
  };
});

Mock.mock('api/menu', 'delete', ({
  body
}) => {
  const id = body.get('id')[0];
  let menuList = getMenuList();
  const index = menuList.findIndex((menu) => menu.id === id);
  const [removed] = menuList.splice(index, 1);
  localStorage.setItem('stepin-menu', JSON.stringify(menuList));
  return {
    code: 0,
    message: 'success',
    data: removed,
  };
});